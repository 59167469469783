module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Patrick Sletvold's Blog","short_name":"Patrick's Blog","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"static/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography","omitGoogleFont":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-webmention/gatsby-browser.js'),
      options: {"plugins":[],"username":"patricksletvold.com","mentions":true,"pingbacks":true,"domain":"patricksletvold.com","fetchLimit":10000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
